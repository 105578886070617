<script setup lang="ts">
import { loadYmap } from "vue-yandex-maps";
import { ref, reactive, onMounted } from "vue";
import caption from "@/assets/svg/caption.svg";

const props = defineProps({
  name: {
    type: String,
    default: "map",
  },
  coordinates: {
    type: Array,
    default: [
      [43.101809, 40.621175],
      [42.724817, 41.453552],
      [43.360393, 40.121177],
      [43.296577, 40.257756],
      [42.62744, 41.733435],
      [43.000806, 41.004994],
      [43.277653, 40.267216],
      [43.277653, 40.267216],
      [43.00225, 41.021675],
      [43.085506, 40.816707],
      [42.999665, 41.020229],
      [43.014209, 40.972142],
      [43.156084, 40.347886],
      [43.16511, 40.516858],
      [43.156084, 40.347886],
      [43.276262, 40.273666],
    ],
  },
});

const settings = {
  apiKey: "c1898160-5ec0-40ed-aec9-f8f211209135",
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
  center: [43.172073, 40.67058],
  zoom: 9,
  controls: [],
};

const rules = reactive({});

const isShowHint = ref(false);

// const coordinates = computed(() => props.coords);

function initHandler() {
  let myMap = new ymaps.Map(props.name, settings, {
    suppressMapOpenBlock: true,
  });
  props.coordinates.forEach((coord) => {
    const placemark = createPlacemark(coord);
    myMap.geoObjects.add(placemark);
  });

  myMap.events.add("click", function (e) {
    let coords = e.get("coords");
    ymaps.geocode(coords).then(function (res) {
      let geoObjectData = res.geoObjects.get(0);
      if (geoObjectData.getCountry() !== "Абхазия") {
        isShowHint.value = true;
        setTimeout(() => (isShowHint.value = false), 4000);
        return;
      }
    });
  });

  function getAddress(coords) {
    ymaps.geocode(coords).then(function (res) {
      let firstGeoObject = res.geoObjects.get(0),
        addressLine = firstGeoObject.getAddressLine();
    });
  }
}

function createPlacemark(coords) {
  return new ymaps.Placemark(
    coords,
    {
      caption: "",
    },
    {
      iconLayout: "default#image",
      iconImageHref: caption,
      iconImageSize: [30, 42],
      iconImageOffset: [-15, -42],
      draggable: false,
    }
  );
}

const isMounted = ref(false);

onMounted(async () => {
  await loadYmap({ ...settings, debug: true });
  ymaps.ready(initHandler);

  isMounted.value = true;
});
</script>

<template>
  <div
    v-if="isMounted"
    :id="name"
    class="!w-full h-[303px] overflow-hidden rounded-3xl ymaps-layers-pane"
  ></div>
</template>

<style scoped>
.ql-toolbar.ql-snow {
  border-radius: 4px;
}

.ql-container.ql-snow {
  border-radius: 4px;
}
</style>

<style scoped>
.quillWrapper {
  height: 220px;
}
</style>
